import * as React from "react"
import ShowNewClaimedNft from "../../components/show-newclaimed-nft";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const  cyborgCultur = ( props ) => {

  const address = props.params.address; 

  return (
      <Layout>
        <section className='section-title white collection artwork'>
          <Seo title="Playground - Cyborg culture" />
          <div className='container clearfix flex'>
              <ShowNewClaimedNft tokenId={props.params.address} collectionName="Cyborg culture" imageUrl="https://ira.0kai-cdn.com/image/1/#token_id/1200/800.png" byArtist="Ira Greenberg" collectionUrl="/cyborg-culture/" />
          </div>
        </section>
      </Layout>
  );
}

export default cyborgCultur
